import React, { useContext } from "react";
import { Container, Box, Flex } from "theme-ui";
import pageContextProvider from "@helpers/pageContextProvider";
import Search from "@widgets/Search";
import { HeaderLogo } from "./Header.Logo";
import { HeaderMenu } from "./Header.Menu";
import { HeaderColorMode } from "./Header.ColorMode";
import HoverControlledDropdown from "@components/HoverControlledDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import SplitButton from "react-bootstrap/SplitButton";
import "./dropdown.css";
import IconButton from "@components/IconButton";
import { FaBell } from "react-icons/fa";
import { FaMobile } from "react-icons/fa";
import { FaLaughBeam } from "react-icons/fa";
import { FaLightbulb } from "react-icons/fa";
import { Stack, Main, Sidebar, Hero } from "@layout";
import CardList from "@components/CardList";
import Card from "@components/Card";
import {
  Posts,
  featuredPosts
} from "../../../../../packages/themes/gatsby-theme-flexiblog-news/src/containers/Posts.jsx";

const styles = {
  wrapper: {
    position: `relative`,
    bg: `headerBg`
  },
  container: {
    position: `relative`,
    zIndex: 10
  },
  logoContainer: {
    flexBasis: [`full`, `full`, `1/3`]
  },
  searchContainer: {
    flexBasis: [`auto`, `auto`, `1/3`],
    minWidth: `auto`,
    order: [3, 3, `unset`],
    mx: 3
  },
  menuContainer: {
    flexBasis: [`auto`, `auto`, `1/3`],
    minWidth: `auto`,
    order: [4, 4, `unset`]
  },
  colorModeContainer: {
    minWidth: `auto`,
    order: [2, 2, `unset`]
  }
};

export const Header = ({ children }) => {
  const context = useContext(pageContextProvider);

  const locationState =
    context.location && context.location.state && context.location.state.key;

  const { services, mobileMenu, darkMode } = context.pageContext;

  const algolia = services && services.algolia;

  return (
    <Box sx={styles.wrapper}>
      <Container variant="compact" sx={styles.container}>
        <Flex variant="layout.header">
          <Box sx={styles.logoContainer}>
            <HeaderLogo />
          </Box>
          <Box sx={styles.searchContainer}>
            {algolia && <Search locationState={locationState} />}
          </Box>
          <Box sx={styles.menuContainer}>
            <HeaderMenu locationState={locationState} mobileMenu={mobileMenu} />
          </Box>



          <Box sx={styles.colorModeContainer}>
            {darkMode && <HeaderColorMode />}
          </Box>
        </Flex>
      </Container>
      {children}
    </Box>
  );
};
