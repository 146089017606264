export default {
  color: `alpha`,
  textDecoration: `none`,
  userSelect: `none`,
  ":visited": {
    color: "alpha"
  },
  ":hover": {
    color: "alphaDark"
  }
};
